/*modal-win*/

.md-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2000;
  visibility: hidden;
  width: 100%;
  min-width: 320px;
  max-width: 1130px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  transition: transform 0.6s ease-out;
}

.md-show {
  visibility: visible;
}

.md-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: rgba(0, 0, 0, 0.64);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show ~ .md-overlay {
  opacity: 1;
  visibility: visible;
}

/* Content styles */
.md-content {
  position: relative;
  margin: 0 auto;
  padding: 1em;
  width: 100%;
  min-width: 320px;
  max-width: 754px;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  box-shadow: 0 15px 41px 5px rgba(0, 0, 0, 0.15);
}



/* Individual modal styles with animations/transitions */

/* Effect 2: Slide from the right */
.md-effect-2 .md-content {
  transform: translateX(20%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.md-show.md-effect-2 .md-content {
  transform: translateX(0);
  opacity: 1;
}

.close-win {
  width: 91px;
  padding: 5px;
  display: block;
  margin: 15px auto;
  border: 1px solid #d8d8d8;
  color: #de433c;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.close-win:hover {
  color: #de433c;
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .md-content{
    width: 95%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .md-content {
    width: 85%;
  }
}